import { Link } from "react-router-dom";
import { useEffect } from "react";
import Logo from "../../images/Logo.png";
import { MainButton, Input, PasswordInput } from "../../components";
import { useLOGIN } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading/Loading";

const Login = () => {
  const { handleSubmit, loading, handleChange, error } = useLOGIN();
  const handleSubmitMain = (e) => {
    if (e) {
      e.preventDefault();
    handleSubmit("admin/login");
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmitMain();
    }
  };
  // useEffect(() => {
  //   if (sessionStorage.getItem("fcm_token")) {
  //     setFormData({
  //       ...formData,
  //       fcm_token: sessionStorage.getItem("fcm_token"),
  //     });
  //   }
  // }, []);
  return (
    <Container>
      <Row>
        <div className="bg fixed w-full h-full bg-gray-300 top-0 left-0 z-50 flex items-center justify- ext-center">
          <Col
            md={5}
            className="mx-auto border-2 bg-white border-Secondary rounded-xl p-5 w-full"
          >
            <img src={Logo} alt="" className="w-[200px] mx-auto" />
            <h2 className="text-2xl text-center font-semibold">تسجيل الدخول</h2>
            <Input
              type="name"
              name="email"
              onChange={handleChange}
              title="اسم المستخدم أو البريد الالكتروني"
              onKeyDown={handleKeyDown}
            />

            <PasswordInput name="password" onChange={handleChange} onKeyDown={handleKeyDown}/>
            <p className="flex justify-between text-gray-700 my-3">
              <Link to="/forget-password" className="underline text-Main mx-1">
                هل نسيت كلمة المرور
              </Link>
            </p>
            {loading ? <Loading /> : ""}
            <div className="text-red-600">{error}</div>
            <MainButton
              name="تسجيل دخول"
              onClick={handleSubmitMain}
              className="!w-[70%] py-3 mx-auto !bg-Main"
            />
          </Col>
        </div>
      </Row>
    </Container>
  );
};

export default Login;
