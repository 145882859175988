import Delete from "../Delete/Delete";
import { Link } from "react-router-dom";
import Pagination from "../../Tools/Pagination";
import { AiFillPrinter, AiOutlineEdit } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import { Tooltip } from "react-tooltip";

const Table = ({
  thead,
  tbody,
  tData,
  shows,
  show,
  show2,
  funDelete,
  pageCount,
  edit,
  print,
}) => {
  return (
    <div>
      <Pagination pageCount={pageCount}>
        <div className="lg:!mx-auto md:!mx-auto lg:!w-[1000px]  md:w-[95%] max-sm:!w-[300px]  py-4 overflow-x-auto h-full pb-20">
          <table className=" text-center w-[99%] border overflow-hidden rounded-2xl h-full shadow-md shadow-[rgba(47,126,143,1)] mx-auto  bg-white">
            <thead className="bg-Main text-white w-full pb-52">
              <tr>
                {thead?.map((e, i) => (
                  <td key={i} className="p-5">
                    {e}
                  </td>
                ))}
                <td className="p-5 w-fit">العمليات</td>
              </tr>
            </thead>
            <tbody>
              {tData &&
                tData?.map((e, i) => (
                  <tr key={i}>
                    {tbody?.map((eName, i) => (
                      <>
                        <td key={i} className="border-Main border p-2">
                          {eName.object
                            ? e[eName.name]?.[eName.object]
                            : eName.name
                            ? e[eName.name]
                            : "___"}
                        </td>
                      </>
                    ))}
                    <td className="cursor-pointer relative border-Main border">
                      <div className="flex justify-center items-center gap-3">
                        {print && (
                          <>
                            <div
                              className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
                            >
                              <a id="my-anchor-element">
                                <AiFillPrinter
                                  size={25}
                                  className="text-orange-400"
                                />
                                <Tooltip
                                  anchorSelect="#my-anchor-element"
                                  content="طباعة الفاتورة"
                                />
                              </a>
                            </div>
                          </>
                        )}
                        {show && (
                          <div
                            className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
                          >
                            <Link to={`/${show}/${e.id}/${show2}`}>
                              <FaEye size={27} className="text-blue-700" />
                            </Link>
                          </div>
                        )}
                        {edit && (
                          <div
                            className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
                          >
                            <Link to={`/${edit}/${e.id}`}>
                              <AiOutlineEdit
                                size={30}
                                className="text-green-700"
                              />
                            </Link>
                          </div>
                        )}
                        {shows && (
                          <div
                            className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
                          >
                            <Link to={`/${shows}/${e.id}`}>
                              <FaEye size={27} className="text-blue-700" />
                            </Link>
                          </div>
                        )}

                        {funDelete && (
                          <>
                            <div id="clickdel">
                              <Delete
                                id={e.name}
                                onClick={() => funDelete(e)}
                              />
                            </div>
                            <Tooltip
                              anchorSelect="#clickdel"
                              
                              style={{ background: "#475569" }}
                            >
                               اضغط لحذف الرسالة 
                            </Tooltip>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Pagination>
    </div>
  );
};

export default Table;
