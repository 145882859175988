import { useEffect, useState } from "react";
import { usePOST } from "../../Tools/APIs/useMyAPI";
import { Title } from "../../components";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading/Loading";

const ChangePassword = () => {
  const [biShow, setBiShow] = useState(false);
  // const { handleSubmit, error, loading, handleChangeInput } = usePOST({});
  // const handleSubmitBlue = () => {
  //   handleSubmit("admin/change-password", true);
  // };

  const [password, setPassword] = useState(false);
  const {
    setFormData,
    handleSubmit,
    error,
    loading,
    handleChangeInput,
    formData,
  } = usePOST({});
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit("admin/change-password");
  };

  useEffect(() => {
    setFormData({
      ...formData,
      verification_code: sessionStorage.getItem("code"),
      email: sessionStorage.getItem("email"),
    });
  }, []);
  return (
    <div>
      <Title title="تغيير كلمة المرور" />
      <Col md={7} className="mx-auto px-5 mt-5">
        <Row justify="center">
          <div className="bg-white border-2 border-Secondary rounded-2xl py-10 md:px-16">
            <Col>
              <input
                name="old_password"
                type={biShow ? "text" : "password"}
                className="border-2 border-Secondary py-4 rounded-2xl my-3 w-full"
                placeholder="كلمة المرور القديمة"
                 onChange={handleChangeInput}
              />
            </Col>
            <Col>
              <input
                name="password"
                type={biShow ? "text" : "password"}
                className="border-2 border-Secondary py-4 rounded-2xl my-3 w-full"
                placeholder="كلمة المرور الجديدة"
                 onChange={handleChangeInput}
              />
            </Col>
            <Col>
              <input
                name="password_confirmation"
                type={biShow ? "text" : "password"}
                className="border-2 border-Secondary py-4 rounded-2xl my-3 w-full"
                placeholder="أعد كتابة كلمة المرور"
                 onChange={handleChangeInput}
              />
            </Col>
            <div className="text-end text-l flex items-center justify-start gap-2">
              <input
                type="checkbox"
                className="relative w-5 h-5"
                 onChange={(e) => setBiShow(e.target.checked)}
              />
              <span>إظهار كلمة المرور</span>
              {loading ? <Loading /> : ""}
              <div className="text-red-600">{error}</div>
            </div>
            <div
               onClick={handleSubmitMain}
              className="cursor-pointer w-full border border-Secondary py-3 mt-10 rounded-2xl text-white font-semibold text-xl text-center bg-gradient-to-l to-Main from-Secondary"
            >
              حفظ التغيرات
            </div>
          </div>
        </Row>
      </Col>
    </div>
  );
};

export default ChangePassword;
