import React, { useEffect, useState } from 'react'
import { Col, Row } from '../../Tools/Grid-system';
import { Back, Input, MainButton } from '../../components';
import AddImage from '../../Tools/AddImage/AddImage';
import JoditEditor from "jodit-react";
import { fileUrl, useFETCH, usePOST } from '../../Tools/APIs/useMyAPI';
import { useParams } from 'react-router-dom';
import { Data } from 'victory';
import Loading from '../../Tools/Loading/Loading';

function EditSection() {
  const { id } = useParams();
 
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    handleChangeArrayImages,
    handleCheckedArray,
    setViewImages,
    setImages,
    images,
    viewImages,
    viewFile,
    setViewFile,
    formData,
    loading,
  } = usePOST();
  
  const { data } = useFETCH(id === "add" ? "" : `admin/properties-sections/${id}`);
 console.log(data)

  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(id === "add" ? `admin/properties-sections` : `admin/properties-sections/${id}`);
  };

  let dataOld = data?.data.data;
  
  useEffect(() => {
    id !== "add" &&
      setFormData({
        _method: 'PUT',
        en_name: dataOld?.en_name || "",
        ar_name: dataOld?.ar_name || "",
        ar_description:dataOld?.ar_description || "",
        en_description: dataOld?.en_description || "",

   
      });
  }, [dataOld]);

  const [userImage, setUserImage] = useState(dataOld?.image);
  useEffect(() => {
    setUserImage(dataOld?.image);
  }, [dataOld?.image]);

    return (
        <div>
          <Row justify={"center"}>
            <Col md={9}>
              <Row
                justify="center"
                className="border-2 border-Main p-8 rounded-xl"
              >
                 <Col>
                <h1 className="mb-4 text-2xl text-black font-bold">أضف صورة القسم </h1>
                <AddImage
                    name="image"
                    newImage={viewFile}
                    oldImage={userImage && fileUrl +  userImage}
                    clickDeleteOldImage={() => {
                      setUserImage('')
                    }}
                    onChange={handleChangeInput}
                    clickDeleteImage={() => {
                      setViewFile("");
                      setFormData({ ...formData, image: "" });
                    }}
                  />
                </Col>
                <Input
                  type="text"
                  name="ar_name"
                   value={formData?.ar_name}
                  onChange={handleChangeInput}
                  title="اسم القسم باللغة العربية "
                />
                 <Input
                  type="text"
                  name="en_name"
                   value={formData?.en_name}
                  onChange={handleChangeInput}
                  title=" اسم القسم باللغة الانكليزية"
                />
                 <Input
                  type="text"
                  name="ar_description"
                   value={formData?.ar_description}
                  onChange={handleChangeInput}
                  title=" اسم الوصف باللغة بالعربي"
                />
                 <Input
                  type="text"
                  name="en_description"
                   value={formData?.en_description}
                  onChange={handleChangeInput}
                  title=" اسم الوصف باللغة الانكليزية"
                />
               
               
                {loading ? <Loading /> : ""}
                {/* <div className="text-red-500 font-semibold">{error}</div> */}
                <Col col={6}>
                  <MainButton
                     onClick={handleSubmitMain}
                    name="حفظ"
                    className="h-full px-4 py-3"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Back />
        </div>
      );
}

export default EditSection
