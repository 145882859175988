import { Route, Routes } from "react-router-dom";
import { Container } from "./Tools/Grid-system";
import { Navbar, SideBar } from "./layout/index";
import { P403, P404, P500 } from "./Tools/Error";
import { RequireAuth, useFETCH } from "./Tools/APIs/useMyAPI";
import logo from "./images/Logo.png";
import {
  ChangePassword,
  Code,
  ContactUs,
  ContentSection,
  ContentService,
  EditContactUs,
  EditOffers,
  EditOurServices,
  EditSec,
  EditSection,
  EditService,
  ForgetPassword,
  Login,
  Message,
  Offers,
  OurServices,
  Sec,
  Section,
  Service,
  SetPassword,
} from "./pages";
import Home from "./pages/Home/Home";

const App = () => {
  const { data } = useFETCH(
    localStorage.getItem("token") ? "admin/check/auth" : ""
  );

  return (
    <>
      <div className="relative flex ">
        <SideBar />
        <div className="relative w-full">
          <Navbar />
          <Container>
            <Routes>
              <Route path="login" element={<Login />} />
              <Route path="forget-password" element={<ForgetPassword />} />
              <Route path="verification-code" element={<Code />} />
              <Route path="set-password" element={<SetPassword />} />
              <Route
                path=""
                element={
                  <h1 className="grid place-content-center h-[75vh]">
                    <img
                      src={logo}
                      alt=""
                      className="w-[450px] px-5 h-[350px] object-contain max-md:w-[250px] bg-white  rounded-3xl shadow-xl border-t"
                    />
                  </h1>
                }
              />
              <Route element={<RequireAuth />}>
                <Route path="*" element={<P404 />} />
                <Route path="403" element={<P403 />} />
                <Route path="500" element={<P500 />} />

                <Route index element={<Home />} />

                <Route path="Offers">
                  <Route index element={<Offers />} />
                  <Route path=":id" element={<EditOffers />} />
                </Route>
                <Route path="our-services">
                  <Route index element={<OurServices />} />
                  <Route path=":id" element={<EditOurServices />} />
                  <Route path=":id/Service" element={<Service />} />
                  <Route path=":id/Service/:idd" element={<EditService />} />
                  <Route
                    path=":id/Service/:idd/ContentService"
                    element={<ContentService />}
                  />
                </Route>
                <Route path="section">
                  <Route index element={<Section />} />
                  <Route path=":id" element={<EditSection />} />
                  <Route path=":id/sec" element={<Sec />} />
                  <Route path=":id/sec/:idd" element={<EditSec />} />
                  <Route
                    path=":id/sec/:idd/ContentSection"
                    element={<ContentSection />}
                  />
                </Route>

                <Route path="Contact-US">
                  <Route index element={<ContactUs />} />
                  <Route path=":id" element={<EditContactUs />} />
                </Route>

                <Route path="message" element={<Message />} />
                <Route path="Change-Password" element={<ChangePassword />} />
              </Route>
            </Routes>
          </Container>
        </div>
      </div>
    </>
  );
};

export default App;
