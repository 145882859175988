import Input from "../../components/Input/Input";
import { useEffect, useState } from "react";
import { MainButton } from "../../components";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { usePOST } from "../../Tools/APIs/useMyAPI";
import Loading from "../../Tools/Loading/Loading";
function SetPassword() {
  const [password, setPassword] = useState(false);
  const {
    setFormData,
    handleSubmit,
    error,
    loading,
    handleChangeInput,
    formData,
  } = usePOST({});
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit("admin/reset-password", "/login");
  };

  useEffect(() => {
    setFormData({
      ...formData,
      verification_code: sessionStorage.getItem("code"),
      email: sessionStorage.getItem("email"),
    });
  }, []);
  return (
    <Container>
      <Row className="h-[70vh] flex justify-center items-center">
      <div className="bg fixed w-full h-full bg-gray-300 top-0 left-0 z-50 flex items-center justify- ext-center">

        <Col
          md={5}
          className="mx-auto space-y-3 bg-white p-8 rounded-3xl border-t shadow-xl "
        >
          <h1 className="text-2xl font-semibold text-center">كلمة السر</h1>
          <Input
            name="password"
            type={!password ? "password" : "text"}
            title="أدخل كلمة المرور"
            onChange={handleChangeInput}
          />
          <Input
            name="password_confirmation"
            type={!password ? "password" : "text"}
            title="تأكيد كلمة المرور"
            onChange={handleChangeInput}
          />
          <div className="text-lg mt-5 flex items-center">
            <input
              type="checkbox"
              onClick={() => setPassword(!password)}
              name="checkbox"
              className="mx-3 w-fit"
            />
            <span>إظهار كلمة المرور</span>
          </div>
          {loading ? <Loading /> : ""}
          <div className="text-red-600">{error}</div>
          <MainButton
            className=" mx-auto mt-2 !bg-Main"
            name="تأكيد كلمة المرور"
            onClick={handleSubmitMain}
          />
        </Col>
        </div>
      </Row>
    </Container>
  );
}
export default SetPassword;
