import { useState } from "react";
import MainButton from "../Buttons/MainButton";
import { Link, useParams } from "react-router-dom";
import { RiDeleteBin5Line } from "react-icons/ri";
import { AiOutlineEdit } from "react-icons/ai";
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { BiShow } from "react-icons/bi";
import { motion } from "framer-motion"
import { useFETCH } from "../../Tools/APIs/useMyAPI";


const CardAll = ({ children, deleteClick, edit ,link , className ,tooltip , picture,ourSer_name,service_name,Section_name,offer }) => {
  const [sure, setSure] = useState(false);

  
 
  return (
    <>
      <div
        onClick={() => setSure(false)}
        className={`${
          sure ? "" : "hidden"
        } fixed w-full h-full top-0 left-0 bg-black/30 z-40 flex justify-center items-center`}
      >
        <div className="bg-white z-50 rounded-3xl w-[500px] max-w-[500px] min-h-[200px] ">
        
         { ourSer_name && (
          <p className="font-semibold text-3xl max-sm:text-xl text-center py-7">
          هل انت متأكد من حذف قسم  
          <span className="text-red-600 mx-1">({ourSer_name})</span> ؟
          </p>)}

          { service_name && (
          <p className="font-semibold text-3xl max-sm:text-xl text-center py-7">
          هل انت متأكد من حذف لخدمة  
          <span className="text-red-600 mx-1">({service_name})</span> ؟
          </p>)}
          { Section_name && (
          <p className="font-semibold text-3xl max-sm:text-xl text-center py-7">
          هل انت متأكد من حذف عقار   
          <span className="text-red-600 mx-1">({Section_name})</span> ؟
          </p>)}
          { offer === true && (
          <p className="font-semibold text-3xl max-sm:text-xl text-center py-7">
          هل انت متأكد من حذف هذا العرض   
          ؟
          </p>)}
          <div className="flex gap-3 items-end justify-center m-5">
            <MainButton
              onClick={deleteClick}
              name="حذف"
              className="!py-3 !px-14 !bg-red-600  hover:!bg-opacity-70"
            />
            <button
              onClick={() => setSure(false)}
              className=" !bg-Secondary hover:!bg-opacity-70 text-white shadow-md shadow-black !px-14 hover:bg-Secondary hover:text-white font-semibold py-3 rounded-xl ml-5 "
            >
              إلغاء
            </button>
          </div>
        </div>
      </div>

      <div
       
        className= {`bg-slate-50 border-2 border-Secondary  overflow-hidden rounded-3xl sm:p-2 w-full h-full mt-5  space-y-5 shadow-md shadow-black  ${className}`}
      >
        
        <div className="flex gap-1 mb-1">
          {edit && (
            <div
              className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
            >
              <Link to={edit || ""}>
                <a id="clickedit">
                    <AiOutlineEdit
                     size={30} 
                     color="#0099B4"
                     />
                </a>
                <Tooltip anchorSelect="#clickedit" clickedit style={{background: "#475569"}} >
                    <button>  اضغط لتعديل العنصر </button>
                </Tooltip>

              </Link>
            </div>
          )}
          

          {deleteClick && (
            <div className="w-10 h-10 rounded-full hover:bg-slate-200 flex cursor-pointer justify-center items-center ">
              <a id="clickdelete">
                <RiDeleteBin5Line
                  size={30}
                  color="#CC2132"
                  onClick={() => setSure(true)}
                /> 
              </a>
              <Tooltip anchorSelect="#clickdelete" clickdelete style={{background: "#475569"}}>
              <button>  اضغط لحذف العنصر </button>
              </Tooltip>
            </div>
          )}
          
            {tooltip && (
          <div className="w-10 h-10 rounded-full hover:bg-slate-200 flex cursor-pointer justify-center items-center">
            <Link to={tooltip || ""} >
            <a id="clickshow">
            <BiShow 
            size={30}
            color="green"/>
            
            </a>
            <Tooltip anchorSelect="#clickshow" clickshow style={{background: "#475569"}} >
              <button>لعرض المزيد من التفاصيل</button>
            </Tooltip>
            </Link>
            </div>
            )}
            
        </div>
        <div>
          {picture && (
          <motion.img 
          src={picture} 
          alt="" 
          className='w-full h-[200px] '   
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}/>
        )}
        
        {children}
        </div>
      </div>
      
    </>
  );
};

export default CardAll;
