import { useContextHook } from "../../Context/ContextOPen";
import { Link, NavLink } from "react-router-dom";
import logo from "../../images/Logo.png";
import { TiMessages } from "react-icons/ti";
import { MdOutlineLocalOffer } from "react-icons/md";
import { MdViewCompact } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { RiLockPasswordLine } from "react-icons/ri";
import "./SideBar.css";
import { BiHome } from "react-icons/bi";
function SideBar() {
  const { openMenu, changeMenu } = useContextHook();
  const sidebar = [
    // { name: "الصفحة الرئيسية", link: "/", icon: <BiHome size={30} /> },
    { name: "العروض", link: "/Offers", icon: <TiMessages size={30} /> },
    {
      name: "الخدمات",
      link: "/our-services",
      icon: <MdOutlineLocalOffer size={30} />,
    },
    { name: " العقارات", link: "/section", icon: <MdViewCompact size={30} /> },
    { name: "اتصل بنا", link: "/contact-uS", icon: <FiPhoneCall size={30} /> },
    { name: "الرسائل", link: "/Message", icon: <TiMessages size={30} /> },
    {
      name: "تغيير كلمة السر",
      link: "/Change-Password",
      icon: <RiLockPasswordLine size={30} />,
    },
  ];
  return (
    <>
      <div
        className={`${
          openMenu
            ? "w-[350px] max-lg:!w-[300px] max-sm:!w-[250px]"
            : " w-[0px]"
        } ${
          openMenu
            ? "!max-md:translate-x-0 !max-md:w-3/4 -right-0"
            : "!max-md:translate-x-full"
        }  bg-Secondary sidebar h-[100vh]  max-md:h-[100%] sticky max-md:fixed right-0 top-0 transition-all overflow-auto py-10  max-md:z-50 `}
      >
        <div className="px-3">
          <ul className="text-white text-center font-semibold space-y-4 max-sm:mt-10">
            <li className="">
              <Link to="/">
                <img
                  src={logo}
                  alt=""
                  className="w-[200px] h-[100px] px-5 shadow-sm object-contain rounded-[20px] bg-white mx-auto"
                />
              </Link>
            </li>
            {sidebar.map((e) => (
              <li className="">
                <NavLink
                  to={e.link}
                  className="text-xl py-2 block rounded-3xl hover:bg-Main hover:text-white"
                >
                  <div className="flex justify-start items-center p-1 gap-7 mx-3 ">
                    {e.icon} {e.name}
                  </div>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div
        onClick={changeMenu}
        style={{ transition: "0.4s" }}
        className={`${
          openMenu ? "" : "hidden"
        }  !max-md:w-full h-full bg-black/20 fixed -left-0 top-0 transition-all z-40`}
      />
    </>
  );
}

export default SideBar;
