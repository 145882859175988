import React from "react";
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryTooltip,
} from "victory";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
// import Loading from "../../Tools/Loading";

const VictoryChartComp = () => {
  const { data } = useFETCH("admin/offers", "admin/offers");
  const { data: dataServices } = useFETCH("admin/services-sections");
  const { data: dataSection } = useFETCH(
    "admin/properties-sections",
    `admin/properties-sections`
  );

  const offer_total = data?.data?.data?.total;
  const Services_total = dataServices?.data?.data?.total;
  const Section_total = dataSection?.data?.data?.total;

  const Home = [
    { name: "عدد العروض", value: offer_total },
    { name: "عدد الخدمات", value: Services_total },
    { name: "عدد العقارات", value: Section_total },
  ];

  const homeAll = Home.map((e) => ({
    name: e.name,
    value: e.value,
  }));

  const Boss = {
    axis: {
      style: {
        tickLabels: {
          fill: "#2c3e50", // لون النص
          fontSize: 14,
          padding: 10,
        },
        axisLabel: {
          fill: "#34495e", // لون النص
          fontSize: 18,
          padding: 20,
        },
        grid: {
          stroke: "#ecf0f1", // لون الشبكة
        },
        ticks: {
          stroke: "#34495e", // لون الخطوط القصيرة
          size: 6,
        },
      },
    },
    bar: {
      style: {
        data: {
          fill: "#e74c3c", // لون الأعمدة
          width: 20, // عرض الأعمدة
          stroke: "#c0392b", // لون الحدود
          strokeWidth: 1,
        },
        labels: {
          fontSize: 14,
          fill: "#2c3e50", // لون النصوص
        },
      },
    },
    chart: {
      style: {
        parent: {
          backgroundColor: "#ecf0f1", // لون الخلفية
        },
      },
    },
  };

  return (
    <div className="w-full h-full flex justify-center">
      <div className="xl:!w-[700px] max-xl:!w-[600px] max-lg:!w-[500px]  ">
        <VictoryChart
          // adding the material theme provided with Victory
          theme={Boss}
          domainPadding={60}
          // width={550}
          // style={{
          //   parent: { border: "1px solid #ccc" }
          // }}
        >
          <VictoryAxis
            tickValues={[1, 2, 3]}
            tickFormat={Home.map((e) => e.name)}
          />

          <VictoryAxis
            dependentAxis
            tickValues={[0, 2, 4, 6, 8]} // هنا يتم تحديد مقدار الزيادة على المحور العمودي
            tickFormat={(t) => `${t}`} // صيغة العرض
          />
          <VictoryBar
            data={homeAll}
            x="name"
            y="value"
            labels={({ datum }) => `${datum.name}: ${datum.value}`}
            labelComponent={<VictoryTooltip />}
            style={{
              data: { fill: "#c43a31" },
              labels: { fontSize: "12px", fill: "#333" },
            }}
            barWidth={20}
          />
        </VictoryChart>
      </div>
    </div>
  );
};

export default VictoryChartComp;
