import React from "react";
import {
  Add,
  CardAll,
  Data,
  DataEnglish,
  MainButton,
  Slider,
  Title,
} from "../../components";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { RxSpaceBetweenHorizontally } from "react-icons/rx";
import { TbNumbers } from "react-icons/tb";

import l1 from "../../images/Rectangle 9 (4).png";
import l2 from "../../images/Rectangle 9 (2).png";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { useParams } from "react-router-dom";
import Loading from "../../Tools/Loading/Loading";
import Map from "../../components/map/Map";
import FixedMap from "../../components/map/FixedMap";

function ContentSection() {
  const { id, idd } = useParams();

  const {
    data: dataSec,
    isLoading,
    deleteItem,
  } = useFETCH(`admin/psections/${id}/properties`, `admin/properties`);

  const { data } = useFETCH(
    id === "add" ? `` : `admin/properties/${idd}`
  );

  let dataAll = data?.data?.data;
  console.log(dataAll?.latitude);

  const fixedLat = dataAll?.latitude;
  const fixedLng = dataAll?.longitude;

  return (
    <Container>
      {/* {isLoading ? <Loading /> : ""} */}
      <Row className="flex justify-between items-center my-8">
        <Col md={8}>
          <Title
            title={` العقارات / ${dataSec?.data?.section?.ar_name} /   ${dataAll?.ar_name} `}
          />
        </Col>
        <Col md={4} className=" flex justify-center items-center ">
          <MainButton name="تعديل" link={`/section/${id}/sec/${idd}`} />
        </Col>
      </Row>
      <Row className="mb-10">
        <Col
          md={5}
          sm={8}
          className="p-10 mx-auto  border-2 border-Main shadow-xl shadow-black "
        >
          <span className="text-black md:text-2xl flex justify-center items-center  mx-auto font-bold ">
            {" "}
            اسم العقار : {dataAll?.ar_name}{" "}
          </span>
        </Col>
        <Col
          md={5}
          sm={8}
          className="p-10 mx-auto  border-2 border-Main shadow-xl shadow-black"
        >
          <span className="text-black md:text-2xl flex justify-center items-center  mx-auto font-bold text-center">
            {" "}
            service name : {dataAll?.en_name}{" "}
          </span>
        </Col>
      </Row>

      <Row>
        {}
        <Col className="mx-auto space-y-10 ">
          <Slider />
        </Col>
      </Row>
      <Row className="py-10 ">
        <Col
          xs={11}
          className="mx-auto mt-6 sm:flex sm:justify-between  px-3 py-10  "
        >
          <Col
            xs={6}
            className=" flex justify-center items-center gap-5 mx-auto md:py-0 py-5"
          >
            <div className="border-2 border-slate-200 shadow-2xl shadow-slate-200 w-40 h-40 rounded-2xl flex-col pt-4 ">
              <RxSpaceBetweenHorizontally
                size={40}
                color="blue"
                className="mx-auto"
              />
              <p className="text-3xl text-Main text-center ">ِArea</p>
              <p className="text-black text-2xl text-center">{dataAll?.area}</p>
            </div>
            <div className="border-2 border-slate-200 shadow-2xl shadow-slate-200 w-40 h-40 rounded-2xl flex-col pt-4">
              <TbNumbers size={40} color="blue" className="mx-auto" />
              <p className="text-3xl text-Main text-center ">Number</p>
              <p className="text-black text-2xl text-center">
                {dataAll?.number}
              </p>
            </div>
          </Col>
          <Col xs={6} className="space-y-4 flex-col mx-auto">
            <div className="flex justify-center items-center">
              <p className="text-black text-3xl font-bold">Total Price</p>
            </div>
            <div className="flex justify-center items-center">
              <span className="text-slate-400 text-3xl text-end">Month/</span>
              <span className="text-Main text-5xl text-end">
                {dataAll?.total_price}
              </span>
            </div>
          </Col>
        </Col>
        <Col sm={11} className="mx-auto py-5 border-Main border-2">
          <Data name="وصف العقار" text={dataAll?.ar_description} />
          <div className="border-2 border-Main h-1 w-full my-5"></div>
          <Data
            dir="ltr"
            name="compound Description"
            text={dataAll?.en_description}
          />
        </Col>
      </Row>
      <Col sm={11} className="mx-auto py-5 space-y-8 ">
        <Data name="العنوان" text={dataAll?.address} />
        {/* <iframe 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6843723.269530299!2d49.00905669714547!3d33.11797529528847!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1557823d54f54a11%3A0x6da561bba2061602!2z2KfZhNi52LHYp9mC!5e0!3m2!1sar!2s!4v1706612760432!5m2!1sar!2s" width="1000" height="600" style={{border: "0" , width:"100%"  }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
        </iframe> */}

        {fixedLat === undefined || fixedLng === undefined ? (
          <Loading />
        ) : (
          <FixedMap defaultLat={fixedLat} defaultLng={fixedLng} fixedLat={fixedLat} fixedLng={fixedLng} />
        )}
      </Col>
    </Container>
  );
}

export default ContentSection;
