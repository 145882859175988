import React from "react";
import { Link } from "react-router-dom";

const Title = ({ title, className, link, ball }) => {
  return (
    <div className="relative mr-2 pr-2 inline-block   ">
      {ball ? (
        <span className="absolute top-3/5 -translate-y-1 right-0 text-3xl font-bold">
          »
        </span>
      ) : (
        <span className="w-2 h-2 rounded-full bg-Main absolute top-1/2 -translate-y-1/2 right-0"></span>
      )}
      <Link to={link || ""}>
        <div className={`text-start mb-3 mr-3 ${className} text-3xl font-bold  `}>
          {title}
        </div>
      </Link>
    </div>
  );
};

export default Title;
