import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "../../Tools/Grid-system";
import { Back, Input, MainButton } from "../../components";
import AddImage from "../../Tools/AddImage/AddImage";
import JoditEditor from "jodit-react";
import { useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { useParams } from "react-router-dom";
import AddFile from "../../Tools/AddFile";
import Map from "../../components/map/Map";
import Loading from "../../Tools/Loading/Loading";
import { Marker } from "mapbox-gl";

function EditSec() {
  const { id, idd } = useParams();

  const {
    setFormData,
    viewImages,
    setViewImages,
    handleChangeArrayImages,
    handleChangeInput,
    handleSubmit,
    setImages,
    formData,
    loading,
    counter,
    setCounter,
    successfulPost,
    images,
  } = usePOST({});

  const [oldImages, setoldImages] = useState({});

  const { data } = useFETCH(
    idd === "add"
      ? ``
      : `admin/properties/${idd}`
  );
 

  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      idd === "add"
        ? `admin/psections/${id}/properties`
        : `admin/psections/${id}/properties/${idd}`
    );
  };

 
 

  function handleLocationSelect({ lat, lng }) {
    setFormData({...formData, latitude: lat, longitude: lng})
  }

  let dataOld = data?.data.data;
  useEffect(() => {
    setoldImages(dataOld?.files);
  }, []);

  console.log(dataOld)

  const [userImages, setUserImages] = useState(dataOld?.data?.data?.images);

  useEffect(() => {
    setUserImages(dataOld?.images);
  }, [dataOld?.images]);

  useEffect(() => {
    if (successfulPost) {
      setImages({});
      setCounter(0);
      setFormData({});
    }
  }, [successfulPost]);

 

  useEffect(() => {
    idd !== "add" &&
      setFormData({
        _method: "PUT", 
        en_name: dataOld?.en_name || "",
        ar_name: dataOld?.ar_name || "",
        ar_description: dataOld?.ar_description || "",
        en_description: dataOld?.en_description || "",
        total_price: dataOld?.total_price || "",
        number: dataOld?.number || "",
        area: dataOld?.area || "",
        address: dataOld?.address || "",
        total_price: dataOld?.total_price || "",
        is_best: dataOld?.is_best || "",
      });
  }, [dataOld]);

  return (
    <div>
      <Row justify={"center"}>
        <Col md={9} className="">
          <Row
            justify="center"
            className="border-2 border-Secondary p-8 rounded-xl space-y-7"
          >
            <Col>
              <h1 className="mb-4 text-2xl text-black font-bold">
                أضف صورة أو أكثر للعقار
              </h1>
              <AddFile
                type="IMAGES"
                name="files[]"
                newImages={viewImages}
                setnewImages={setViewImages}
                setFormData={setFormData}
                onChange={handleChangeArrayImages}
                setImagesToSend={setImages}
                formData={formData}
                counter={counter}
                setCounter={setCounter}
                setOldImages={setoldImages}
                viewImages={viewImages}
                setViewImages={setViewImages}
                images={userImages}
                setUserImages={setUserImages}
                imagesToSend={images}
              />
            </Col>
            {/* <Col>
              <h1 className="mb-4 text-2xl text-black font-bold">
                {" "}
                أضف فيديو أو أكثر للعقار{" "}
              </h1>
              <AddImage
                name="video"
                newImage={viewFile}
                oldImage={dataOld?.video && fileUrl + dataOld?.video}
                onChange={handleChangeInput}
                clickDeleteImage={() => {
                  setViewFile("");
                  setFormData({ ...formData, video: "" });
                }}
                title="أضف فيديو"
              />
            </Col> */}

            <Input
              type="text"
              name="ar_name"
              value={formData?.ar_name}
              onChange={handleChangeInput}
              title=" اسم العقار باللغة العربية"
            />
            <Input
              type="text"
              name="en_name"
              value={formData?.en_name}
              onChange={handleChangeInput}
              title=" اسم العقار باللغة الانكليزية"
            />

            <Input
              type="number"
              name="total_price"
              value={formData?.total_price}
              onChange={handleChangeInput}
              title="السعر"
            />

            <Input
              type="text"
              name="area"
              value={formData?.area}
              onChange={handleChangeInput}
              title="المساحة"
            />
            <Input
              type="number"
              name="number"
              value={formData?.number}
              onChange={handleChangeInput}
              title="أضف رقم العقار"
            />
            <Input
              type="text"
              name="address"
              value={formData?.address}
              onChange={handleChangeInput}
              title=" العنوان"
            />

            <div className=" border-2 border-Main text-l flex items-center justify-start py-2 rounded-2xl gap-2 !w-[97%]">
              <input
                type="checkbox"
                onChange={handleChangeInput}
                name="is_best"
                className="relative w-5 h-5"
              />
              <span className="text-xl font-bold "> عقار مميز</span>
            </div>

            <h1 className="mb-4 text-2xl text-black font-bold">
              وصف العقار باللغة العربية
            </h1>
            <JoditEditor
              value={formData?.ar_description}
              onChange={(e) => setFormData({ ...formData, ar_description: e })}
            />
            <h1 className="mb-4 text-2xl text-black font-bold">
              وصف العقار باللغة الانكليزية
            </h1>
            <JoditEditor
              value={formData?.en_description}
              onChange={(e) => setFormData({ ...formData, en_description: e })}
            />

            <Map
              defaultLat={33.5138}
              defaultLng={36.2765}
              onLocationSelect={handleLocationSelect}
            />

            {loading ? <Loading /> : ""}
            {/* <div className="text-red-500 font-semibold">{error}</div> */}
            <Col col={6}>
              <MainButton
                onClick={handleSubmitMain}
                name="حفظ"
                className="h-full px-4 py-3"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Back />
    </div>
  );
}

export default EditSec;
