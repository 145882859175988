import React from "react";
import MainButton from "./MainButton";
import { Link } from "react-router-dom";

const Back = ({ name, onClick, link }) => {
  return (
    <div className=" fixed bottom-10 left-5 z-50">
      <Link to={link || ""}>
        <MainButton
          className="py-2 px-5 !bg-red-600"
          name="إلغاء"
          onClick={() => window.history.go(-1)}
        />
      </Link>
    </div>
  );
};

export default Back;
