import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, Autoplay } from "swiper";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "swiper/css/navigation";
import "swiper/css";
import "swiper/css/autoplay";
import "./Slider.css";
import { Col } from "../../Tools/Grid-system";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { useParams } from "react-router-dom";
// import './style.css'

const Slider = ({ image }) => {
  const [thumbs, setThumbs] = useState(null);

  const [Service, setService] = useState(false);
  const [Section, setSection] = useState(false);

  const { id, idd } = useParams();

  const { data } = useFETCH(`admin/services/${idd}`);

  const { data: dataSec } = useFETCH(`admin/properties/${idd}`);
  

  let dataAll = data?.data?.data;
  let dataSection = dataSec?.data.data;
console.log(dataSection);
  return (
    <>
      <Swiper
        loop={true}
        modules={[Navigation, Thumbs, Autoplay]}
        thumbs={{ swiper: thumbs && !thumbs.destroyed ? thumbs : null }}
        navigation
        autoplay={true}
        className="lg:w-[700px] h-[475px] shadow-2xl shadow-black border-2 border-Main bg-black "
      >
        {/* <SwiperSlide>
            <video   controls className=" video">
              <source src="./v2.mp4" />
                Your browser does not support the video tag.
            </video>
        </SwiperSlide> */}

        {
          dataAll?.images?.map((f) => (
            <SwiperSlide>
              <img src={fileUrl + f.file} className="w-full h-full p-2" />
            </SwiperSlide>
          ))}
           {
          dataSection?.images?.map((f) => (
            <SwiperSlide>
              <img src={fileUrl + f.file} className="w-full h-full p-2" />
            </SwiperSlide>
          ))}

        
      </Swiper>

      {/* <Swiper
              slidesPerView={8}     
              onSwiper={setThumbs}
              className='lg:w-[700px] sm:h-[80px] shadow-2xl shadow-black cursor-pointer'
            >
        <SwiperSlide>
            <video width="100%" style={{height: "100%"}}  controls className=" w-full h-full object-cover">
              <source src="./v1.mp4" />
            </video>
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://swiperjs.com/demos/images/nature-2.jpg" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://swiperjs.com/demos/images/nature-3.jpg" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://swiperjs.com/demos/images/nature-4.jpg" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://swiperjs.com/demos/images/nature-5.jpg" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://swiperjs.com/demos/images/nature-6.jpg" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://swiperjs.com/demos/images/nature-7.jpg" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://swiperjs.com/demos/images/nature-8.jpg" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://swiperjs.com/demos/images/nature-9.jpg" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://swiperjs.com/demos/images/nature-10.jpg" />
        </SwiperSlide>
      </Swiper> */}
    </>
  );
};
export default Slider;
