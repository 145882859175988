import { BiMenu } from "react-icons/bi";
import Logo from "../../images/Logo.png";
import { useContextHook } from "../../Context/ContextOPen";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { Tooltip } from "react-tooltip";
import { MainButton } from "../../components";
import { Col, Row } from "../../Tools/Grid-system";
import { logout } from "../../Tools/APIs/useMyAPI";
const Navbar = () => {
  const { changeMenu } = useContextHook();
  const [sure, setSure] = useState(false);
  
  
  return (
    <>
      {sure && (
        <>
          <div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-60 z-50"
            onClick={() => setSure(false)}
          ></div>
          <div
            className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg/black/60 z-50 flex justify-center items-center max-sm:w-full`}
          >
            <div className="bg-white z-[60] rounded-3xl w-[500px] max-sm:w-[90%] min-h-1600px] py-8 space-y-8">
              <p className="font-semibold text-3xl max-sm:text-2xl text-center">
                هل انت متأكد من تسجيل الخروج ؟
              </p>
              {/* {loading ? <Loading /> : ""} */}
              <Row justify="center">
                <Col sm={4}>
                  <MainButton name="نعم"    
                  onClick={() => logout("admin/logout")} 
                  />
                </Col>
                <Col sm={4}>
                  <div
                    className={`bg-red-600 shadow-md shadow-black rounded-xl hover:bg-opacity-70 text-center font-semibold text-white cursor-pointer w-full px-5 py-3`}
                    onClick={() => setSure(false)}      >
                    إلغاء
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
      <div className="sticky  top-0 left-0 z-4 bg-Secondary flex items-center justify-between border-b-2 border-black px-4 py-2 w-full !z-50">
        <div>
          <BiMenu
            size={40}
            className="cursor-pointer text-white "
            onClick={changeMenu}
          />
        </div>
        <div className="">
          <img
            src={Logo}
            alt=""
            className="w-48 h-16 object-contain bg-white rounded-lg p-2"
          />
        </div>
        <a id="my-anchor-element">
          <RiLogoutBoxRLine
            onClick={() => setSure(true)}
            className="cursor-pointer text-white"
            size={30}
          />
          <Tooltip anchorSelect="#my-anchor-element" content="تسجيل خروج" />
        </a>
      </div>
    </>
  );
};

export default Navbar;
