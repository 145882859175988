import React from "react";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { Add, CardAll, Data, DataEnglish, Title } from "../../components";
import services_1 from "../../images/Rectangle 9.png";
import services_2 from "../../images/Rectangle 9 (1).png";
import services_3 from "../../images/Rectangle 9 (2).png";
import services_4 from "../../images/Rectangle 9 (3).png";
import services_5 from "../../images/Rectangle 9 (4).png";
import { Link } from "react-router-dom";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import Loading from "../../Tools/Loading/Loading";
import Pagination from "../../Tools/Pagination";

function OurServices() {
  const { data, isLoading, deleteItem } = useFETCH(
    "admin/services-sections",
    "admin/services-sections"
  );
  console.log(data)
  
  let dataAll = data?.data?.data?.data;
  console.log(dataAll)

  return (
    <>
      <Title title="الخدمات" />
      <Container>
        {/* <Row>
          <Col lg={4} md={6} className="">
            <CardAll
              edit={"/our-services/3"}
              deleteClick={"/our-services/3"}
              tooltip={"/our-services/3/Service"}
              picture={services_1}
            >
              <Data name="اسم القسم" text="التصميم" />
              <Data
                name="وصف القسم"
                text="نملك الكثير من ادوات التصميم المتطورة والمرتكزة على اعمق وأدق التفاصيل المعتمدة في جميع انحاء العالم"
              />
              <div className="border-2 border-Main w-full h-1 my-3"></div>
              <DataEnglish name="section name" text="Designe" />
              <DataEnglish
                name="section Description"
                text="We have many advanced design tools based on the deepest and most accurate details adopted all over the world"
              />
            </CardAll>
          </Col>
        </Row> */}

        {isLoading ? <Loading /> : ""}
        {data?.data.data.data == 0 ? (
          <div className="h-[73vh] flex justify-center items-center text-2xl font-semibold text-center">
            <p className="border-2 border-Secondary rounded-xl px-3 py-8 bg-white">
              لا يوجد خدمات لعرضها  
            </p>
          </div>
        ) : (
          <Pagination
            pageCount={Math.ceil(
              data?.data.data?.total / data?.data.data?.per_page
            )}
          >
            <Row justify="center">
              {dataAll?.map((e, key) => (
                <Col key={key} lg={4} sm={12} className="!mt-5">
                  <CardAll
                  ourSer_name={e.ar_name}
                   edit={`${e.id}`} 
                   deleteClick={() => deleteItem(e)}
                   tooltip={`/our-services/${e.id}/Service`}>
                    <img
                      src={fileUrl + e.image}
                      alt=""
                      className="w-full h-[220px] object-contain"
                    />
                    <Data name="اسم القسم" text={e.ar_name} />
                    <Data
                      name="وصف القسم"
                      text={e.ar_description}
                    />
                    <div className="border-2 border-Main w-full h-1 my-3"></div>
                   <div dir="ltr">
                   <Data name="section name" text={e.en_name} />
                    <Data
                      name="section Description"
                      text={e.en_description}
                    />
                   </div>

                    
                  </CardAll>
                </Col>
              ))}
            </Row>
          </Pagination>
        )}
        <Add link="/our-services/add" />
      </Container>
    </>
  );
}

export default OurServices;
