import { CardAll, Data, Table, Title } from "../../components";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { useFETCH, useFilter } from "../../Tools/APIs/useMyAPI";
// import Pagination from "../../Tools/Pagination";
import Loading from "../../Tools/Loading/Loading";
import { useParams } from "react-router-dom";

const Message = () => {
  // const { filter } = useFilter({});
  // const { data, isLoading, deleteItem } = useFETCH(
  //   `messages?page=${filter.get("page") ? filter.get("page") : 1}`,
  //   "messages"
  // );

  const { id } = useParams();

  const { data, isLoading, deleteItem } = useFETCH(
    "admin/messages",
    `admin/messages`
  );
  let dataAll = data?.data.data.data;
  

  return (
    <>
      <Title title="الرسائل" />
      <Container>
        {isLoading ? <Loading /> : ""}
        {data?.data.data.data == 0 ? (
          <div className="h-[73vh] flex justify-center items-center text-2xl font-semibold text-center">
            <p className="border-2 border-Secondary rounded-xl px-3 py-8 bg-white">
              لا يوجد رسائل
            </p>
          </div>
        ) : (
          <Row>
            <Col lg={12} className=" flex justify-center ">
            {isLoading ? <Loading /> : ""}
              <Table
                thead={["الاسم ", "الايميل", "رقم الهاتف", " الرسالة"]}
                pageCount={Math.ceil(
                  data?.data.data?.total / data?.data.data?.per_page
                )}
                tData={dataAll}
                tbody={[
                  { name: "name" },
                  { name: "email" },
                  { name: "phone" },
                  { name: "message" },
                ]}
                funDelete={deleteItem}
              />
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default Message;
