import React from 'react'
import { Add, CardAll, Data, DataEnglish, Title } from '../../components'
import { Col, Container, Row } from '../../Tools/Grid-system'
import Loading from '../../Tools/Loading/Loading'
import { fileUrl, useFETCH } from '../../Tools/APIs/useMyAPI'
import l1 from '../../images/Frame 34221.png'
import l2 from '../../images/Rectangle 9 (4).png'
import l3 from '../../images/Rectangle 9 (5).png'
import l4 from '../../images/Rectangle 9 (6).png'
import { motion } from "framer-motion"
import Pagination from '../../Tools/Pagination'


function Offers() {

    const { data, isLoading, deleteItem } = useFETCH("admin/offers","admin/offers")
    
    let dataAll = data?.data.data.data;
  
   

    return (

        <Container>
            <Title title="العروض" />
            {/* {isLoading ? <Loading /> : ""}    */}
            {/* <Row>
                <Col lg={4} md={6}    >
                    <CardAll
                        edit={"/Offers/2"}
                        deleteClick={() => deleteItem()}
                        picture={l1}
                    >
                       
                        <Data name="وصف العرض" text="الموقع جميل جدا واطلالةجميل جدا واطلالة  بحرية رائعة" />
                        <DataEnglish name="Offer description" text="The location is very beautiful and has a wonderful sea view" />
                    </CardAll>
                </Col>
             
            </Row> */}
      {isLoading ? <Loading /> : ""}
      {data?.data.data.data == 0 ? (
        <div className="h-[73vh] flex justify-center items-center text-2xl font-semibold text-center">
          <p className="border-2 border-Secondary rounded-xl px-3 py-8 bg-white">
            لا يوجد عروض   
          </p>
        </div>
      ) : (
        <Pagination
          pageCount={Math.ceil(
            data?.data.data?.total / data?.data.data?.per_page
          )}
        >
          <Row justify="center">
            {dataAll?.map((e,key) => (
              <Col key={key} lg={4} sm={12}>
                <CardAll
                    offer={true}
                     edit={`${e.id}`}
                     deleteClick={() => deleteItem(e)}
                >
                  
                    <img
                      src={fileUrl + e.image}
                      alt=""
                      className="w-full h-[220px] object-contain"
                    />
                  
                  <Data name="الوصف بالعربي" text={e.ar_description} />
                  <Data dir="ltr" name="الوصف بالانكليزي" text={e.en_description} />
                  
                </CardAll>
              </Col>
            ))}
          </Row>
        </Pagination>
      )}
            <Add link='/Offers/add' />
        </Container>

    )
}

export default Offers
