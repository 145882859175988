import { useEffect } from "react";
import { usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading/Loading";
import { Input, MainButton } from "../../components";
function ForgetPassword() {
  const { handleSubmit, error, loading, handleChangeInput, formData } = usePOST(
    {}
  );
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit("admin/send/verification-code", "/verification-code");
  };
  useEffect(() => {
    sessionStorage.setItem("email", formData?.email);
  }, [formData]);
  return (
    <Container>
      <Row className="h-[70vh] flex justify-center items-center">
      <div className="bg fixed w-full h-full bg-gray-300 top-0 left-0 z-50 flex items-center justify- ext-center">

        <Col
          md={5}
          className="mx-auto space-y-3 bg-white p-8 rounded-3xl border-t shadow-xl "
        >
          <h1 className="text-2xl font-semibold text-center">
            هل نسيت كلمة المرور
          </h1>
          <Input
            name="email"
            onChange={handleChangeInput}
            title="ادخل البريد الالكتروني"

          />
          {loading ? <Loading /> : ""}
          <div className="text-red-600">{error}</div>
          <MainButton
            className="mt-6 !w-[60%] mx-auto !bg-Main"
            name="التالي"
            onClick={handleSubmitMain}
          />
        </Col>
       </div>
      </Row>
    </Container>
  );
}
export default ForgetPassword;
