import parse from "html-react-parser";

function Data({ name, text, img, className ,dir}) {
  return (
    <div
      className={`font-bold text-Main mt-3 p-1 w-full ${className}`}
      dir={dir}
    >
      <span className="ml-1 flex justify-center text-lg ">{name} </span>
      {/* <span className="ml-1">{text} </span> */}
      <span className="text-black">{text && parse(text)}</span>
    </div>
  );
}
export default Data;
