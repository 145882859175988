import { Col, Row } from "../Grid-system";
import { fileUrl } from "../APIs/useMyAPI";
import { AiOutlineCloudUpload } from "react-icons/ai";

const AddFile = ({
  id,
  title,
  name,
  onChange,
  newImages,
  setnewImages,
  setImagesToSend,
  images,
  setFormData,
  setUserImages,
  formData,
  counter,
  setCounter,
  imagesToSend,
}) => {


  const limit = images?.length + newImages?.length;
  const slots = [];
  let imagesTemp = {};
  let counterTemp = 0;
  let index = 0;

  for (let i = 0; i < 4 - limit; i++) {
    slots[i] = i;
  }


  return (
    <div className="my-1">
      <div className=" font-bold text-Secondary text-lg">{title}</div>
      <div
        onClick={() => document.getElementById(id || "image").click()}
        className={`border border-[#B3B3B3] outline-[#2684FF] m-1 py-2 px-3 rounded-md flex justify-between text-[#9CA3AF] cursor-pointer `}>
        <div className="">{title || "أضف صورة"}</div>
        <div>
          <AiOutlineCloudUpload size={22} />
        </div>
      </div>
      <input
        id={id || "image"}
        type="file"
        name={name}
        onChange={onChange}
        className="hidden"
        accept="image/*"
        // multiple
        // disabled={limit >= 4}
      />
      <Row className="!mt-6">
        <Col
          col={12}
          className="gap-4 mx-auto w-full flex justify-center flex-wrap">
          {newImages?.length > 0 &&
            newImages.map((e) => (
              <Col className="max-md:w-[40%]">
                <div className="relative border-2 border-Secondary rounded-lg">
                  <span
                    onClick={() => {
                      setnewImages((prev) =>
                        prev.filter((image) => image.value !== e.value)
                      );
                      for (const [key, value] of Object.entries(imagesToSend)) {
                        if (value.name === e.name && counterTemp < 1) {
                          counterTemp = counterTemp + 1;
                          continue;
                        }
                        imagesTemp[`files[${index}]`] = value;
                        index++;
                      }
                      counterTemp = 0;
                      setImagesToSend(imagesTemp);
                    }}
                    className="bg-red-700 w-6 h-6 flex justify-center items-center font-bold text-white cursor-pointer z-10 -top-2 rounded-full absolute">
                    X
                  </span>
                  <img
                    src={e.value}
                    alt=""
                    className="object-cover rounded-lg md:w-40 h-40 w-full"
                  />
                </div>
              </Col>
            ))}
          {images?.length > 0 &&
            images?.map((e) => (
              <Col className="max-md:w-[40%]">
                <div className="relative border-2 border-Secondary rounded-lg">
                  <span
                    onClick={() => {
                      setFormData((prev) => ({
                        ...prev,
                        [`trashed_files[${counter}]`]: `${e.id}`,
                        // [`trashed_files`]: e.id,
                      }));
                      setCounter(counter + 1);
                      setUserImages((prev) =>
                        prev.filter((image) => image.id !== e.id)
                      );
                    }}
                    className="bg-red-700 w-6 h-6 flex justify-center items-center font-bold text-white cursor-pointer z-10 -top-2 rounded-full absolute">
                    X
                  </span>
                  <img
                    src={images?.length > 0 ? fileUrl + e.file : ""}
                    alt="Old Profile Pic"
                    className="object-cover rounded-lg md:w-40 h-40 w-full"
                  />
                </div>
              </Col>
            ))}
          {/* {slots.map((e) => (
            <Col key={e} className="max-md:!w-[40%]">
              <div className=" rounded-lg md:w-40 h-40 w-full border-2 border-gray-400"></div>
            </Col>
          ))} */}
        </Col>
      </Row>
    </div>
  );
};

export default AddFile;
