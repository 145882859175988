// import React, { useState } from "react";
// import GoogleMapReact from "google-map-react";
// import { FaMapMarkerAlt } from "react-icons/fa";

// const Map = ({ onClickMap }) => {

//   // const [latitude, setLatitude] = useState(null);
//   // const [longitude, setLongitude] = useState(null);

//   // function handleMapClick({ lat, lng }) {
//   //   setLatitude(lat);
//   //   setLongitude(lng);
//   // }


//   return (
//     <div style={{ height: "100vh", width: "100%" }}>
//       <GoogleMapReact
//         bootstrapURLKeys={{ key: "AIzaSyCfkdKB1BFRb0TndYiUfBM-6Gst2F7ol6s" }}
//         defaultCenter={{ lat: 33.513769088918686, lng: 36.27651247889148 }}
//         defaultZoom={11}
//         onClick={onClickMap}
        
//       >
//         {/* {laat && lnng && onClickMap && (
//           <Marker lat={laat} lng={lnng} onclick={onClickMap} />
         
//          ) }   */}

//         <div onClick={onClickMap}
//           style={{ position: "absolute", transform: "translate(-50%, -50%)" }}>
//           <FaMapMarkerAlt className="text-3xl text-blue-800" />
//         </div>


//       </GoogleMapReact>
//     </div>
//   );
// };

// // const Marker = ({ lat, lng,onclick }) => (
// //   <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)' }} >
// //     <FaMapMarkerAlt className='text-3xl text-blue-800'    />

// //   </div>
// // );

// export default Map;

import React from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// إعداد أيقونة الدبوس
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

 const LocationMarker = ({ setLatLng }) => {
  const [position, setPosition] = React.useState(null);

  useMapEvents({
    click(e) {
      setPosition(e.latlng);
      setLatLng(e.latlng);
    },
  });

  return position != null && (
    <Marker position={position} />
  );
};

const Map = ({ defaultLat, defaultLng, onLocationSelect }) => {
  return (
    <MapContainer center={[defaultLat, defaultLng]} zoom={13} className='z-40' style={{ height: '100vh', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <LocationMarker setLatLng={onLocationSelect} />
    </MapContainer>
  );
};

export default Map;