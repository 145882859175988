import React from "react";
import { Add, CardAll, Data, DataEnglish, Title } from "../../components";
import { Col, Container, Row } from "../../Tools/Grid-system";
import services_1 from "../../images/Rectangle 9.png";
import services_2 from "../../images/Rectangle 9 (6).png";
import services_3 from "../../images/Rectangle 9 (4).png";
import services_4 from "../../images/Rectangle 9 (3).png";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import Loading from "../../Tools/Loading/Loading";
import Pagination from "../../Tools/Pagination";
import { useParams } from "react-router-dom";

function Service() {

  const {id,idd}= useParams()

  const { data, isLoading, deleteItem } = useFETCH(
    `admin/ser_section/${id}/services`,   `admin/services`
  );
  
  
  let dataAll = data?.data?.data?.data;
  
 

  return (
    <>
      <Title title={`الخدمات / ${data?.data?.section?.ar_name}`} />
      <Container>
        {/* <Row >
                <Col lg={4} md={6} className='mx-auto'>
                    
                    <CardAll
                        edit={'/our-services/3/Service/3'}
                        deleteClick={'/'}
                        tooltip={"/our-services/3/Service/3/ContentService"}
                       picture={services_3}

                    >
                        
                        <Data name="اسم الخدمة" text="التصميم"  />
                        <Data name="وصف الخدمة" text="نملك الكثير من ادوات التصميم المتطورة والمرتكزة على اعمق وأدق التفاصيل المعتمدة في جميع انحاء العالم" />
                        <div className='border-2 border-Main w-full h-1 my-3'></div>
                        <DataEnglish name="service name" text="Designe" />
                        <DataEnglish name="service Description" text="We have many advanced design tools based on the deepest and most accurate details adopted all over the world" />
                    </CardAll>
                    
                </Col>
              
          
            </Row> */}

        {isLoading ? <Loading /> : ""}
        {data?.data.data.data == 0 ? (
          <div className="h-[73vh] flex justify-center items-center text-2xl font-semibold text-center">
            <p className="border-2 border-Secondary rounded-xl px-3 py-8 bg-white">
              لا يوجد خدمات داخل هذا القسم
            </p>
          </div>
        ) : (
          <Pagination
            pageCount={Math.ceil(
              data?.data.data?.total / data?.data.data?.per_page
            )}
          >
            <Row justify="center">
              {dataAll?.map((e, key) => (
                <Col key={key} lg={4} sm={12} className="!mt-5">
                  <CardAll
                  service_name={e.ar_name}
                    edit={`${e.id}`}
                    deleteClick={() => deleteItem(e)}
                    tooltip={`/our-services/${id}/Service/${e.id}/ContentService`}
                  >
                    <img
                      src={fileUrl + e.images[0]?.file}
                      alt=""
                      className="w-full h-[220px] object-contain"
                    />
                    <Data name="السعر" text={e.price} className="text-center" />
                    <Data name="اسم القسم" text={e.ar_name} />
                    <Data name="وصف القسم" text={e.ar_description} />
                    <div className="border-2 border-Main w-full h-1 my-3"></div>
                    <div dir="ltr">
                      <Data name="section name" text={e.en_name} />
                      <Data
                        name="section Description"
                        text={e.en_description}
                      />
                    </div>
                  </CardAll>
                </Col>
              ))}
            </Row>
          </Pagination>
        )}

        <Add link={`/our-services/${id}/Service/add`} />
      </Container>
    </>
  );
}

export default Service;
