import React, { useEffect, useState } from "react";
import { Col, Row } from "../../Tools/Grid-system";
import { Back, Input, MainButton, TextArea } from "../../components";
import AddImage from "../../Tools/AddImage/AddImage";
import JoditEditor from "jodit-react";
import { fileUrl, useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { useParams } from "react-router-dom";
import AddFile from "../../Tools/AddFile";

function EditService() {
  const { id,idd } = useParams();

  const {
    setFormData,
    viewImages,
    setViewImages,
    handleChangeArrayImages,
    handleChangeInput,
    handleSubmit,
    setImages,
    formData,
    loading,
    counter,
    setCounter,
    successfulPost,
    images
  } = usePOST({});
  const [oldImages, setoldImages] = useState({});

  
  
  
  const { data } = useFETCH(
    idd === "add" ? `admin/ser_sections/${id}/services` : `admin/services/${idd}`
  );
  
  
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      idd === "add"
      ? `admin/ser_sections/${id}/services`
      : `admin/ser_sections/${id}/services/${idd}`
    );
  };

  
  let dataOld = data?.data.data;
  useEffect(() => {
    setoldImages(dataOld?.files);
  }, []);
  
  const [userImages, setUserImages] = useState(dataOld?.data?.data?.images);
  useEffect(() => {
    setUserImages(dataOld?.images);
  }, [dataOld?.images]);
  
  useEffect(() => {
    if (successfulPost) {
      setImages({});
      setCounter(0);
      setFormData({});
    }
  }, [successfulPost]);

  console.log(dataOld)

  useEffect(() => {
    idd !== "add" &&
      setFormData({ 
        _method: 'PUT',
        en_name: dataOld?.en_name || "",
        ar_name: dataOld?.ar_name || "",
        ar_description: dataOld?.ar_description || "",
        en_description: dataOld?.en_description || "",
        price: dataOld?.price || "",
      });
  }, [dataOld]);

  return (
    <div>
      <Row justify={"center"}>
        <Col md={9}>
          <Row
            justify="center"
            className="border-2 border-Main p-8 rounded-xl space-y-7"
          >
            <Col>
              <h1 className="mb-4 text-2xl text-black font-bold">
                {" "}
                أضف صورة الخدمة{" "}
              </h1>
              <AddFile
                type="IMAGES"
                name="files[]"
                newImages={viewImages}
                setnewImages={setViewImages}
                setFormData={setFormData}
                onChange={handleChangeArrayImages}
                setImagesToSend={setImages}
                formData={formData}
                counter={counter}
                setCounter={setCounter}
                setOldImages={setoldImages}
                viewImages={viewImages}
                setViewImages={setViewImages}
                images={userImages}
                setUserImages={setUserImages}
                imagesToSend={images}
              />
            </Col>
            <Input
              upTitle="اسم الخدمة باللغة العربية"
              type="text"
              name="ar_name"
              value={formData?.ar_name}
              onChange={handleChangeInput}
              title="اسم الخدمة باللغة العربية"
            />
            <Input
              upTitle="اسم الخدمة باللغة الانكليزية"
              type="text"
              name="en_name"
              value={formData?.en_name}
              onChange={handleChangeInput}
              title="اسم الخدمة باللغة الانكليزية"
            />
            <Input
              upTitle="السعر"
              type="text"
              name="price"
              value={formData?.price}
              onChange={handleChangeInput}
              title="السعر"
            />
            <h1 className="mb-4 text-2xl text-black font-bold">
              وصف الخدمة باللغة العربية
            </h1>
            <JoditEditor
              value={formData?.ar_description}
              onChange={(e) => setFormData({ ...formData, ar_description: e })}
            />
            <h1 className="mb-4 text-2xl text-black font-bold">
              وصف الخدمة باللغة الانكليزية
            </h1>
            <JoditEditor
              value={formData?.en_description}
              onChange={(e) => setFormData({ ...formData, en_description: e })}
            />

            {/* {loading ? <Loading /> : ""} */}
            {/* <div className="text-red-500 font-semibold">{error}</div> */}
            <Col col={6}>
              <MainButton
                onClick={handleSubmitMain}
                name="حفظ"
                className="h-full px-4 py-3"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Back />
    </div>
  );
}

export default EditService;
