function Input({ title, name, value, type, onChange ,onKeyDown,className,upTitle }) {
  return (
    <div className="my-3 px-1 border-Main">
      <h1 className="text-start mb-1  mx-3 text-2xl font-bold">  {upTitle}</h1>
      <input
       
        type={type || "text"}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={title}
        max={100}
        min={0}
        className={`w-full border-2 border-Main m-1 py-4 rounded-2xl text-start ${className}`}
        onKeyDown={onKeyDown}
      />
      
    </div>
  );
}
export default Input;
