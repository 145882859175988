import React from 'react'
import { Col, Container, Row } from '../../Tools/Grid-system'
import { Add, CardAll, Data, DataEnglish, Title } from '../../components'
import sec_1 from '../../images/Rectangle 9 (5).png'
import sec_2 from '../../images/Rectangle 9 (6).png'
import { useParams } from 'react-router-dom'
import { fileUrl, useFETCH } from '../../Tools/APIs/useMyAPI'
import Loading from '../../Tools/Loading/Loading'
import Pagination from '../../Tools/Pagination'


function Sec() {

    const {id,idd} = useParams()
    const { data, isLoading, deleteItem } = useFETCH(`admin/psections/${id}/properties` , `admin/properties`)

  
    let dataAll = data?.data.data;
    
console.log(data)
    
    return (
        <Container >
            {isLoading ? <Loading /> : ""}
            <Title title={`العقارات / ${data?.data?.section?.ar_name}`} />

            {data?.data?.data?.length == 0 ? (
        <div className="h-[73vh] flex justify-center items-center text-2xl font-semibold text-center">
          <p className="border-2 border-Secondary rounded-xl px-3 py-8 bg-white">
            لا يوجد عقارات داخل هذا القسم
          </p>
        </div>
      ) : (
        <Pagination
          pageCount={Math.ceil(
            data?.data.data?.total / data?.data.data?.per_page
          )}
        >
            <Row>
            {dataAll?.map((e,key) => (
                <Col key={key} lg={4} md={6}>
                    <CardAll
                    Section_name={e.ar_name}
                    edit={`${e.id}`}
                    deleteClick={()=>deleteItem(e)}
                    tooltip={`/section/${id}/sec/${e.id}/ContentSection`}
                    >
                        <img
                      src={fileUrl + e.images[0]?.file}
                      alt=""
                      className="w-full h-[220px] object-contain"
                    />
                        
                        <Data name="اسم العقار" text={e.ar_name}  />
                        <Data name="وصف العقار" text={e.ar_description} />
                        <div className='border-2 border-Main w-full h-1 my-3'></div>
                        <Data dir="ltr" name="compunds name" text={e.en_name} />
                        <Data dir="ltr" name="compunds Description" text={e.en_description} />

                    </CardAll>
                </Col>
            ))}
            </Row>
            </Pagination>
      )}
            <Add link={`/section/${id}/sec/add`} />
        </Container>
      )
}

export default Sec
