import { Col, Container, Row } from "../../Tools/Grid-system";
import {
  CardAll,
  Data,
  DataEnglish,
  MainButton,
  Title,
} from "../../components";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { SwiperSlide, Swiper } from "swiper/react";
import l1 from "../../images/Frame 34221.png";
import l2 from "../../images/Rectangle 9 (4).png";
import l3 from "../../images/Rectangle 9 (5).png";
import l4 from "../../images/Rectangle 9 (6).png";
import services_1 from "../../images/Rectangle 9.png";
import services_2 from "../../images/Rectangle 9 (1).png";
import services_3 from "../../images/Rectangle 9 (2).png";
import services_4 from "../../images/Rectangle 9 (3).png";
import services_5 from "../../images/Rectangle 9 (4).png";
import sec_1 from "../../images/Rectangle 9 (5).png";
import sec_2 from "../../images/Rectangle 9 (6).png";
import "./Home.css";
import VictoryChartComp from "../../components/VictoryChartComp/VictoryChartComp";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import Loading from "../../Tools/Loading/Loading";

export default function Home() {
  const { data, isLoading } = useFETCH("admin/offers", "admin/offers");

  const { data: dataServices } = useFETCH(
    "admin/services-sections",
    "admin/services-sections"
  );
  const { data: dataSection } = useFETCH(
    "admin/properties-sections",
    `admin/properties-sections`
  );

  let dataAll = data?.data.data.data;
  let dataSer = dataServices?.data.data.data;
  let dataSec = dataSection?.data.data.data;

  return (
    <Container>
      
      <Title title="الصفحة الرئيسية" />
      {isLoading ? <Loading /> : ""}

      <Row justify={"center"} className="!mt-5">
        <Col lg={4} md={4} className="">
          <Row
            justify={"center"}
            className="border-4 border-Secondary py-2 !mx-2 rounded-2xl shadow-md shadow-Secondary"
          >
            <Col
              sm={8}
              md={8}
              lg={12}
              className="!mt-2 flex flex-col items-center justify-center   "
            >
              <h1 className="font-semibold  text-2xl  max-lg:text-xl ">
                {" "}
                عدد العروض{" "}
              </h1>
              <h3
                className="font-bold  !mt-2 text-Main text-3xl"
                style={{ textShadow: "2px 2px black" }}
              >
                {data?.data?.data?.total}
              </h3>
            </Col>
          </Row>
        </Col>

        <Col lg={4} md={4} className="">
          <Row
            justify={"center"}
            className="border-4 border-Secondary py-2 !mx-2 rounded-2xl shadow-md shadow-Secondary"
          >
            <Col
              sm={8}
              md={8}
              lg={8}
              className="!mt-2 flex flex-col items-center justify-center "
            >
              <h1 className="font-semibold text-2xl  max-lg:text-xl">
                {" "}
                عدد الخدمات{" "}
              </h1>
              <h3
                className="font-bold  !mt-2 text-Main text-3xl "
                style={{ textShadow: "2px 2px black" }}
              >
                {dataServices?.data?.data?.total}
              </h3>
            </Col>
          </Row>
        </Col>

        <Col lg={4} md={4} className="">
          <Row
            justify={"center"}
            className="border-4 border-Secondary py-2 !mx-2 rounded-2xl shadow-md shadow-Secondary"
          >
            <Col
              sm={8}
              md={8}
              lg={8}
              className="!mt-2 flex flex-col items-center justify-center "
            >
              <h1 className="font-semibold  text-2xl max-lg:text-xl">
                {" "}
                عدد العقارات{" "}
              </h1>
              <h3
                className="font-bold  !mt-2 text-Main text-3xl "
                style={{ textShadow: "2px 2px black" }}
              >
                {dataSection?.data?.data?.total}
              </h3>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <VictoryChartComp />
        </Col>
      </Row>
      <Row>
        <Col lg={12} className="flex justify-center ">
          <Title title="العروض" className="text-4xl font-bold text-Secondary" />
        </Col>
      </Row>

      {data?.data.data.data == 0 ? (
        <div className="h-[73vh] flex justify-center items-center text-2xl font-semibold text-center">
          <p className="border-2 border-Secondary rounded-xl px-3 py-8 bg-white">
            لا يوجد عروض لعرضها  
          </p>
        </div>
      ) : (

      <Row justify={"center"} className=" ">
        <Col
          lg={10}
          md={12}
          className="!mt-5  xl:!w-[1050px] max-xl:!w-[800px] max-lg:!w-[500px]"
        >
          <div className=" rounded-2xl">
            <Swiper
              // Install Swiper modules
              className="mySwiper   custom-swiper  "
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              scrollbar={{ draggable: true }}
              breakpoints={{
                1020: {
                  slidesPerView: 2,
                },
                767: {
                  slidesPerView: 1,
                },
                0: {
                  slidesPerView: 1,
                },
              }}
            >
              {dataAll?.map((e, key) => (
                <SwiperSlide>
                  <Col lg={10} md={10} sm={10} className="">
                    <CardAll
                    //  edit={`${e.id}`}
                    //  deleteClick={() => deleteItem(e)}
                  
                    >
                      <img
                        src={fileUrl + e.image}
                        alt=""
                        className="w-full h-[220px] object-contain"
                      />

                      <Data name="الوصف بالعربي" text={e.ar_description} />
                      <Data
                        dir="ltr"
                        name="الوصف بالانكليزي"
                        text={e.en_description}
                      />
                    </CardAll>
                  </Col>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Col>
      </Row> )}
      <Row className="flex justify-end !mt-2">
        <Col lg={4} md={4}>
          <Row>
            <MainButton
              link="/Offers"
              name="عرض جميع العروض"
              className="!bg-Secondary"
            />
          </Row>
        </Col>
      </Row>

      <Row className="!mt-5">
        <Col lg={12} className="flex justify-center ">
          <Title
            title="الخدمات"
            className="text-4xl font-bold text-Secondary max-md:!mt-4"
          />
        </Col>
      </Row>

      {data?.data.data.data == 0 ? (
          <div className="h-[73vh] flex justify-center items-center text-2xl font-semibold text-center">
            <p className="border-2 border-Secondary rounded-xl px-3 py-8 bg-white">
              لا يوجد خدمات لعرضها  
            </p>
          </div>
        ) : (

      <Row justify={"center"} className=" ">
        <Col
          lg={10}
          md={12}
          className="!mt-5 xl:!w-[1050px]  max-xl:!w-[800px] max-lg:!w-[500px] "
        >
          <div className=" rounded-2xl">
            <Swiper
              // Install Swiper modules
              className="mySwiper  custom-swiper  "
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              scrollbar={{ draggable: true }}
              breakpoints={{
                1020: {
                  slidesPerView: 2,
                },
                767: {
                  slidesPerView: 1,
                },
                0: {
                  slidesPerView: 1,
                },
              }}
            >
              {dataSer?.map((e, key) => (
                <SwiperSlide >
                  <Col lg={10} md={10} sm={10} className="">
                    <CardAll
                    //  edit={`${e.id}`}
                    //  deleteClick={() => deleteItem(e)}
                    //  tooltip={`/our-services/${e.id}/Service`}
                    >
                      <img
                        src={fileUrl + e.image}
                        alt=""
                        className="w-full h-[220px] object-contain"
                      />
                      <Data name="اسم القسم" text={e.ar_name} />
                      <Data name="وصف القسم" text={e.ar_description} />
                      <div className="border-2 border-Main w-full h-1 my-3"></div>
                      <div dir="ltr">
                        <Data name="section name" text={e.en_name} />
                        <Data
                          name="section Description"
                          text={e.en_description}
                        />
                      </div>
                    </CardAll>
                  </Col>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Col>
      </Row> )}
      <Row className="flex justify-end !mt-2">
        <Col lg={4} md={4}>
          <Row>
            <MainButton
              link="/our-services"
              name="عرض جميع الخدمات"
              className="!bg-Secondary"
            />
          </Row>
        </Col>
      </Row>

      <Row className="!mt-5">
        <Col lg={12} className="flex justify-center ">
          <Title
            title=" اقسام العقارات"
            className="text-4xl font-bold text-Secondary max-md:mt-4"
          />
        </Col>
      </Row>

      {data?.data.data.data == 0 ? (
          <div className="h-[73vh] flex justify-center items-center text-2xl font-semibold text-center">
            <p className="border-2 border-Secondary rounded-xl px-3 py-8 bg-white">
              لا يوجد عقارات لعرضها   
            </p>
          </div>
        ) : (

      <Row justify={"center"} className=" ">
        <Col
          lg={10}
          md={4}
          className="!mt-5  xl:!w-[1050px]  max-xl:!w-[800px] max-lg:!w-[500px] "
        >
          <div className=" rounded-2xl">
            <Swiper
              // Install Swiper modules
              className="mySwiper  custom-swiper  "
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              scrollbar={{ draggable: true }}
              breakpoints={{
                1020: {
                  slidesPerView: 2,
                },
                767: {
                  slidesPerView: 1,
                },
                0: {
                  slidesPerView: 1,
                },
              }}
            >
              {dataSec?.map((e, key) => (
                <SwiperSlide>
                  <Col lg={10} md={10} sm={10} className="">
                    <CardAll
                      // edit={`${e.id}`}
                      // deleteClick={() => deleteItem(e)}
                      // tooltip={`${e.id}/sec`}
                    >
                      <img
                        src={fileUrl + e.image}
                        alt=""
                        className="w-full h-[220px] object-contain"
                      />
                      <p
                        className="text-center text-black text-2xl font-bold pt-3"
                        style={{ textShadow: "1px 1px black" }}
                      >
                        {e.ar_name}
                      </p>
                      <p
                        className="text-center text-black text-2xl font-bold pt-3"
                        style={{ textShadow: "1px 1px black" }}
                      >
                        {e.en_name}
                      </p>
                    </CardAll>
                  </Col>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Col>
      </Row> )}
      <Row className="flex justify-end !mt-2">
        <Col lg={4} md={4}>
          <Row>
            <MainButton
              link="/section"
              name="عرض اقسام العقارات "
              className="!bg-Secondary"
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
