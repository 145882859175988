import { useParams } from "react-router-dom";
import { fileUrl, useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import AddImage from "../../Tools/AddImage/AddImage";
import { Col, Row } from "../../Tools/Grid-system";
import { Back, Input, MainButton, TextArea } from "../../components";
import JoditEditor from "jodit-react";
import { useEffect, useState } from "react";
import Loading from "../../Tools/Loading/Loading";

function EditOffers() {
  const { id } = useParams();
  const [deleteImgs, setDeleteImgs] = useState(false);
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    handleChangeArrayImages,
    handleCheckedArray,
    setViewImages,
    setImages,
    images,
    viewImages,
    viewFile,
    setViewFile,
    formData,
    loading,
  } = usePOST();

  const { data } = useFETCH(id === "add" ? "" : `admin/offers/${id}`);
  
 

  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(id === "add" ? `admin/offers` : `admin/offers/${id}`);
  };

  let dataOld = data?.data.data;
  
  useEffect(() => {
    id !== "add" &&
    setFormData({
      _method: 'PUT',
      en_description: dataOld?.en_description || "",
      ar_description: dataOld?.ar_description || "",
      image : dataOld?.image || ""
      
    });
  }, [dataOld]);

  const [userImage, setUserImage] = useState(dataOld?.image);
  useEffect(() => {
    setUserImage(dataOld?.image);
  }, [dataOld?.image]);
  

  return (
    <div>
      <Row justify={"center"}>
        <Col md={9}>
          <Row
            justify="center"
            className="border-2 border-Secondary p-8 rounded-xl space-y-7"
          >
              <Col>
                   <h1 className="mb-4 text-2xl text-black font-bold"> أضف صورة العرض   </h1>
                   <AddImage
                    name="image"
                    newImage={viewFile}
                    oldImage={userImage && fileUrl +  userImage}
                    clickDeleteOldImage={() => {
                      setUserImage('')
                    }}
                    onChange={handleChangeInput}
                    clickDeleteImage={() => {
                      setViewFile("");
                      setFormData({ ...formData, image: "" });
                    }}
                  />
                </Col>
            <h1 className="mb-4 text-2xl text-black font-bold">وصف العرض باللغة العربية</h1>
            <JoditEditor
            value={formData?.ar_description}
            onChange={(e) => setFormData({ ...formData, ar_description: e })}
            />
             <h1 className="mb-4 text-2xl text-black font-bold">وصف العرض باللغة الانكليزية</h1>
            <JoditEditor
            value={formData?.en_description}
            onChange={(e) => setFormData({ ...formData, en_description: e })}
            />
           
            {loading ? <Loading /> : ""}
            {/* <div className="text-red-500 font-semibold">{error}</div> */}
            <Col col={6}>
              <MainButton
                 onClick={handleSubmitMain}
                name="حفظ"
                className="h-full px-4 py-3"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Back />
    </div>
  );
};

export default EditOffers
