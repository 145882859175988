import { useEffect, useRef, useState } from "react";
import { MainButton } from "../../components";
import { usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading/Loading";
import Logo from "../../images/Logo.png";

function Code() {
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState("");
  const [value4, setValue4] = useState("");
  const v1 = useRef();
  const v2 = useRef();
  const v3 = useRef();
  const v4 = useRef();
  const v5 = useRef();

  if (value1.length > 0) {
    v2.current.focus();
  }
  if (value2.length > 0) {
    v3.current.focus();
  }
  if (value3.length > 0) {
    v4.current.focus();
  }

  document.onkeyup = function (e) {
    if (e.key === "Enter") {
      v5.current?.click();
    }
  };
  const {
    setFormData,
    handleSubmit,
    error,
    loading,
    dataPlayer,
    successfulPost,
  } = usePOST({});
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit("admin/check/verification-code", "/set-password", true);
  };

  useEffect(() => {
    setFormData({
      verification_code: `${value1}${value2}${value3}${value4}`,
      email: sessionStorage.getItem("email"),
    });
    sessionStorage.setItem("code", `${value1}${value2}${value3}${value4}`);
  }, [value1, value2, value3, value4, dataPlayer?.data.data?.is_valid]);

  return (
    <Container>
      <Row className="h-[70vh] flex justify-center items-center">
        <div className="bg fixed w-full h-full bg-gray-300 top-0 left-0 z-50 flex items-center justify- ext-center">
          <Col
            md={5}
            className="mx-auto space-y-3 bg-white p-8 rounded-3xl border-t shadow-xl"
          >
            <img src={Logo} alt="" className="w-[200px] mx-auto" />
            <h1 className="font-bold text-2xl text-center">رمز التحقق</h1>
            <div className="flex justify-center flex-col items-center h-full  text-center w-2/3 mx-auto ">
              <div>
                <p>لقدأرسلنا رمز التحقق الى إيميلك</p>
                <p>{sessionStorage.getItem("email")}</p>
              </div>
              <div
                style={{ direction: "ltr" }}
                className="flex justify-center items-start gap-3 my-6"
              >
                <input
                  type="number"
                  ref={v1}
                  value={value1}
                  maxLength={1}
                  onChange={(e) => setValue1(e.target.value.slice(0, 1))}
                  className="w-14 max-sm:w-12 max-sm:h-12 p-1 text-white text-4xl max-sm:text-2xl text-center h-14 rounded-md bg-Main"
                />
                <input
                  ref={v2}
                  value={value2}
                  maxLength={1}
                  type="number"
                  onChange={(e) => setValue2(e.target.value.slice(0, 1))}
                  className="w-14 max-sm:w-12 max-sm:h-12 p-1 text-white text-4xl max-sm:text-2xl text-center h-14 rounded-md bg-Main"
                />
                <input
                  value={value3}
                  maxLength={1}
                  type="number"
                  ref={v3}
                  onChange={(e) => setValue3(e.target.value.slice(0, 1))}
                  className="w-14 max-sm:w-12 max-sm:h-12 p-1 text-white text-4xl max-sm:text-2xl text-center h-14 rounded-md bg-Main"
                />
                <input
                  ref={v4}
                  value={value4}
                  maxLength={1}
                  type="number"
                  onChange={(e) => setValue4(e.target.value.slice(0, 1))}
                  className="w-14 max-sm:w-12 max-sm:h-12 p-1 text-white text-4xl max-sm:text-2xl text-center h-14 rounded-md bg-Main"
                />
              </div>

              <ResetCode successfulPost={successfulPost} />
              {loading ? <Loading /> : ""}
              <div className="text-red-600">{error}</div>
          
                  <MainButton
                    name="تحقق"
                    className="!w-[300px] mt-5 !bg-Main"
                    onClick={handleSubmitMain}
                  />
               
            </div>
          </Col>
        </div>
      </Row>
    </Container>
  );
}
export default Code;

const ResetCode = () => {
  const { handleSubmit, successfulPost, setFormData, loading } = usePOST({
    email: sessionStorage.getItem("email"),
  });
  const [remainingTime, setRemainingTime] = useState(60);
  useEffect(() => {
    const timer = setTimeout(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);
    if (remainingTime === 0) {
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
  }, [remainingTime]);
  useEffect(() => {
    if (successfulPost) {
      setRemainingTime(60);
    }
  }, [successfulPost]);
  useEffect(() => {
    setFormData({
      email: sessionStorage.getItem("email"),
    });
  }, [sessionStorage.getItem("email")]);
  return (
    <div className="text-center max-sm:text-sm">
      {loading ? <Loading /> : ""}
      {remainingTime ? (
        <time>{"00:" + remainingTime} </time>
      ) : (
        <div
          className="cursor-pointer text-lg underline"
          onClick={() =>
            handleSubmit("admin/send/verification-code", "", "", true)
          }
        >
          إعادة إرسال الرمز
        </div>
      )}
    </div>
  );
};
