import React, { useEffect, useState } from 'react'
import { Col, Row } from '../../Tools/Grid-system';
import { BsWhatsapp } from "react-icons/bs";
import { MdOutlineMail, MdWorkOutline } from "react-icons/md";
import { BiSave } from "react-icons/bi";
import { FaFacebook, FaInstagram, FaPhone } from "react-icons/fa";
import { Back, MainButton } from '../../components';
import { useContactUs, useFETCH, usePOST } from '../../Tools/APIs/useMyAPI';
import { useParams } from 'react-router-dom';
import Loading from '../../Tools/Loading/Loading';

function EditContactUs() {
  const [formDataContact, setFormDataContact] = useState({});


  const { id } = useParams();
  const [deleteImgs, setDeleteImgs] = useState(false);
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    handleChangeArrayImages,
    handleCheckedArray,
    setViewImages,
    setImages,
    images,
    viewImages,
    formData,
    loading,
  } = usePOST();
  
  const {updateContactUs} = useContactUs()

  const { data } = useFETCH(id === "add" ? "" : `admin/infos`);
console.log(data)
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(id === "add" ? `` : `admin/infos`);
  };

  let dataOld = data?.data.data;
  
  useEffect(() => {
    id !== "add" &&
      setFormData({
        _method: 'PUT',
        phone1: dataOld?.phone1 || "",
        phone2: dataOld?.phone2 || "",
        email1: dataOld?.email1 || "",
        email2: dataOld?.email2 || "",
        instagram: dataOld?.instagram || "",
        facebook: dataOld?.facebook || "",
        whatsapp: dataOld?.whatsapp || "",
       
   
      });
  }, [dataOld]);


  return (
        <>
          <Row justify="center" gap={5}>
            {loading ? <Loading /> : ""}
            <Col
              md={5}
              className=" border-4 border-gray-500 rounded-xl flex flex-between p-4 items-center relative bg-white"
            >
              <FaPhone size={50} className="" color="#FEDADA" />
              <input
                type="number"
                name="phone1"
                 value={formData?.phone1}
                // onChange={(e) =>
                //   setFormDataContact({
                //     ...formDataContact,
                //     phone_number: e.target.value,
                //   })
                // }
                onChange={handleChangeInput}
                placeholder="phone number"
                className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
              />
              <div className=" ">
                <BiSave
                  onClick={() =>
                    updateContactUs("value", formData?.phone1,"admin/infos/phone1/update")
                  }
                  color="#3B5998 "
                  className="mx-1 cursor-pointer"
                  size={35}
                />
              </div>
            </Col>
            <Col
              md={5}
              className=" border-4 border-gray-500 rounded-xl flex flex-between p-4 items-center relative bg-white"
            >
              <FaPhone size={50} className="" color="#FEDADA" />
              <input
                type="number"
                name="phone2"
                value={formData?.phone2}
                // onChange={(e) =>
                //   setFormDataContact({
                //     ...formDataContact,
                //     phone_number: e.target.value,
                //   })
                // }
                onChange={handleChangeInput}
                placeholder="phone number 2"
                className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
              />
              <div className=" ">
                <BiSave
                  onClick={() =>
                    updateContactUs("value", formData?.phone2,"admin/infos/phone2/update")
                  }
                  color="#3B5998 "
                  className="mx-1 cursor-pointer"
                  size={35}
                />
              </div>
            </Col>
            <Col
              md={5}
              className=" border-4 border-gray-500 rounded-xl flex flex-between p-4 items-center relative bg-white"
            >
              <MdOutlineMail size={50} className="" color="#FEDADA" />
              <input
                type="email"
                name="email1"
                value={formData?.email1}
                // }
              onChange={handleChangeInput}
                placeholder="email"
                className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
              />
              <div className=" ">
                <BiSave
                  onClick={() =>
                    updateContactUs("value", formData?.email1,"admin/infos/email1/update")
                  }
                  color="#3B5998 "
                  className="mx-1 cursor-pointer"
                  size={35}
                />
              </div>
            </Col>

            <Col
              md={5}
              className=" border-4 border-gray-500 rounded-xl flex flex-between p-4 items-center relative bg-white"
            >
              <MdOutlineMail size={50} className="" color="#FEDADA" />
              <input
                type="email"
                name="email2"
                value={formData?.email2}
                // onChange={(e) =>
                //   setFormDataContact({
                //     ...formDataContact,
                //     phone_number: e.target.value,
                //   })
                // }
                onChange={handleChangeInput}
                placeholder="email2"
                className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
              />
              <div className=" ">
                <BiSave
                  onClick={() =>
                    updateContactUs("value", formData?.email2,"admin/infos/email2/update")
                  }
                  color="#3B5998 "
                  className="mx-1 cursor-pointer"
                  size={35}
                />
              </div>
            </Col>


            <Col
              md={5}
              className=" border-4 border-gray-500 rounded-xl flex flex-between p-4 items-center relative bg-white"
            >
              <FaInstagram size={60} color="#CB3878" />
              <input
                type="text"
                name="instagram"
                value={formData?.instagram}
                // onChange={(e) =>
                //   setFormDataContact({
                //     ...formDataContact,
                //     instagram: e.target.value,
                //   })
                // }
                onChange={handleChangeInput}
                placeholder="https://url....instagram"
                className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
              />
              <div className=" ">
                <BiSave
                  onClick={() =>
                    updateContactUs("value", formData?.instagram,"admin/infos/instagram/update")
                  }
                  color="#3B5998 "
                  className="mx-1 cursor-pointer"
                  size={35}
                />
              </div>
            </Col>
            <Col
              md={5}
              className="border-4 border-gray-500 rounded-xl flex flex-between p-4 items-center relative bg-white"
            >
              <FaFacebook size={60} color="#3B5998" />
              <input
                type="text"
                name='facebook'
                value={formData?.facebook}
                // onChange={(e) =>
                //   setFormData({
                //     ...formData,
                //     facebook: e.target.value,
                //   })
                // }
                onChange={handleChangeInput}
                placeholder="https://url....facebook"
                className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
              />
              <div className=" ">
                <BiSave
                  onClick={() =>
                    updateContactUs("value", formData.facebook,"admin/infos/facebook/update")
                  }
                  color="#3B5998 "
                  className="mx-1 cursor-pointer"
                  size={35}
                />
              </div>
            </Col>
    
            <Col
              md={5}
              className=" border-4 border-gray-500 rounded-xl flex flex-between p-4 items-center relative bg-white"
            >
              <BsWhatsapp size={60} color="#1DCD2A" />
              <input
                type="text"
                name='whatsapp'
                value={formData?.whatsapp}
                // onChange={(e) =>
                //   setFormData({
                //      ...formData,
                //     whatsapp: e.target.value,
                //   })
                // }
                onChange={handleChangeInput}
                placeholder="https://url...."
                className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
              />
              <div className="">
                <BiSave
                  onClick={() =>
                    updateContactUs("value", formData?.whatsapp,"admin/infos/whatsapp/update")
                  }
                  color="#3B5998 "
                  className="mx-1 cursor-pointer"
                  size={35}
                />
              </div>
            </Col>
            {/* <Col col={6}>
              <MainButton
                onClick={handleSubmitMain}
                name="حفظ"
                className=" px-4 py-3"
              />
            </Col> */}
          </Row>
          <Back />
        </>
      );
  
}

export default EditContactUs
