import { useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";

function PasswordInput({ onChange, value, name,onKeyDown }) {
  const [biShow, setBiShow] = useState(false);
  return (
    <>
      <h1 className="text-start mb-1 mx-3 text-lg ">كلمة السر</h1>
      <div className="flex relative">
        <input
          type={!biShow ? "password" : "text"}
          onChange={onChange}
          value={value}
          name={name}
          autoComplete="new-password"
          placeholder="كلمة السر"
          className="w-full border-2 border-Main m-1 py-4 rounded-2xl text-start"
          onKeyDown={onKeyDown}
        />
        {biShow ? (
          <BiShow
            onClick={() => setBiShow(false)}
            size={25}
            className="absolute left-3 top-5 cursor-pointer"
          />
        ) : (
          <BiHide
            onClick={() => setBiShow(true)}
            size={25}
            className="absolute left-3 top-5 cursor-pointer"
          />
        )}
      </div>
    </>
  );
}
export default PasswordInput;
