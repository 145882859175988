import React, { useState } from "react";
import { CardAll, MainButton, Title } from "../../components";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { BsWhatsapp } from "react-icons/bs";
import { FaFacebook, FaInstagram, FaPhone } from "react-icons/fa";
import parse from "html-react-parser";
import { MdOutlineMail, MdWorkOutline } from "react-icons/md";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import Loading from "../../Tools/Loading/Loading";

function ContactUs() {


  const { data, isLoading, deleteItem } = useFETCH("admin/infos");

  let dataAll = data?.data?.data;

  console.log(dataAll);

  // const icons = [
  //   <FaPhone size={50} className="" color="#FEDADA"/>,
  //   <FaPhone size={50} className="" color="#FEDADA"/>,
  //   <MdOutlineMail size={50} className="" color="#a3a3a3"/>,
  //    <MdOutlineMail size={50} className="" color="#a3a3a3"/>,
  //    <FaFacebook size={50} className="" color="#171fc1"/>,
  //    <FaInstagram size={50} className="" color="#f2096e"/>,
  //   <BsWhatsapp size={50} className="" color="#019400"/>,
  // ]

  return (
    <Container>
      <Title title="تواصل معنا" />
      {isLoading ? <Loading /> : ""}
     
        <Row gap={5} justify="center" className="py-7 px-2">
          {/* { dataAll?.map((e) => ( */}
            <Col
              md={5}
              className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white"
            >
              <FaPhone size={50} className="" color="#FEDADA" />
              <p className="font-semibold px-2 text-Gray text-xl">{dataAll?.phone1}</p>
            </Col>
            <Col
              md={5}
              className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white"
            >
              <FaPhone size={50} className="" color="#FEDADA" />
              <p className="font-semibold px-2 text-Gray text-xl">{dataAll?.phone2}</p>
            </Col>
            <Col
              md={5}
              className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white"
            >
             <MdOutlineMail size={50} className="" color="#a3a3a3"/>
              <p className="font-semibold px-2 text-Gray text-xl">{dataAll?.email1}</p>
            </Col>

            <Col
              md={5}
              className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white"
            >
             <MdOutlineMail size={50} className="" color="#a3a3a3"/>
              <p className="font-semibold px-2 text-Gray text-xl">{dataAll?.email2}</p>
            </Col>

            <Col
              md={5}
              className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white"
            >
             <FaFacebook size={50} className="" color="#171fc1"/>
              <p className="font-semibold px-2 text-Gray text-xl">{dataAll?.facebook}</p>
            </Col>

            <Col
              md={5}
              className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white"
            >
            <FaInstagram size={50} className="" color="#f2096e"/>
              <p className="font-semibold px-2 text-Gray text-xl">{dataAll?.instagram}</p>
            </Col>

            <Col
              md={5}
              
              className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white"
            >
              <BsWhatsapp size={40} className="" color="#019400"/>
              <p className="font-semibold px-2 text-Gray text-xl">{dataAll?.whatsapp}</p>
            </Col>
         
        </Row>
     

      <div className="fixed bottom-10 left-10">
        <MainButton
          link="/contact-us/edit"
          name="تعديل"
          className="px-7 py-2"
        />
      </div>
    </Container>
  );
}

export default ContactUs;
