import React from "react";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { Add, CardAll, Title } from "../../components";
import sec_1 from "../../images/Rectangle 9 (5).png";
import sec_2 from "../../images/Rectangle 9 (6).png";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { useParams } from "react-router-dom";
import Loading from "../../Tools/Loading/Loading";

function Section() {

const {id} = useParams()
  const { data, isLoading, deleteItem } = useFETCH("admin/properties-sections",`admin/properties-sections`)
  console.log(data)

  let dataAll = data?.data.data.data;

  return (
    <Container>
      {isLoading ? <Loading /> : ""}
      <Title title="العقارات" />
      <Row>
      {dataAll?.map((e,key) => (
        <Col key={key} lg={4} md={6}>
          <CardAll
          ourSer_name={e.ar_name}
            edit={`${e.id}`}
            deleteClick={() => deleteItem(e)}
            tooltip={`${e.id}/sec`} 
          >
             <img
                      src={fileUrl + e.image}
                      alt=""
                      className="w-full h-[220px] object-contain"
                    />
            <p
              className="text-center text-black text-2xl font-bold pt-3"
              style={{ textShadow: "1px 1px black" }}
            >
              {e.ar_name}
            </p>
            <p
              className="text-center text-black text-2xl font-bold pt-3"
              style={{ textShadow: "1px 1px black" }}
            >
              {e.en_name}
            </p>
          </CardAll>
        </Col>
      ))}
      </Row>
      <Add link={`/section/add`} />
    </Container>
  );
}

export default Section;
