import React from "react";
import {
  Add,
  CardAll,
  Data,
  DataEnglish,
  MainButton,
  Slider,
  Title,
} from "../../components";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { RxSpaceBetweenHorizontally } from "react-icons/rx";
import { TbNumbers } from "react-icons/tb";

import l1 from "../../images/Rectangle 9 (4).png";
import l2 from "../../images/Rectangle 9 (2).png";
import { useParams } from "react-router-dom";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import Loading from "../../Tools/Loading/Loading";

function ContentService() {

const {id , idd} = useParams()

const { data:dataSer, isLoading, deleteItem } = useFETCH(
  `admin/ser_section/${id}/services`,   `admin/services`
);

const { data } = useFETCH(
  id === "add" ? `` : `admin/services/${idd}`
);
  

  let dataAll = data?.data?.data
  console.log(data)
 

  return (
    <Container>
      {/* {dataAll?.map((e, key) => ( */}
      {isLoading ? <Loading /> : ""}
        <>
      <Row className="flex justify-between items-center my-8">
        <Col md={8}>
          <Title title={` الخدمات / ${dataSer?.data?.section?.ar_name} /  ${dataAll?.ar_name}`} />
        </Col>
        <Col md={4} className=" flex justify-center items-center ">
          <MainButton name="تعديل" link={`/our-services/${id}/Service/${idd}`} />
        </Col>
      </Row>
      <Row>
      
        <Col
          md={5}
          sm={8}
          className="p-10 mx-auto   border-2 border-Main shadow-lg shadow-black "
        >
          <span className='text-black md:text-2xl flex justify-center items-center  mx-auto font-bold text-center'> اسم الخدمة  : {dataAll?.ar_name} </span>
        </Col>
        <Col
          md={5}
          sm={8}
          className="p-10 mx-auto max-md:!mt-4   border-2 border-Main shadow-lg shadow-black"
        >
           <span className='text-black md:text-2xl flex justify-center items-center  mx-auto font-bold text-center'> service name : {dataAll?.en_name} </span>
        </Col>
      </Row>

      <Row>
        <Col className="mx-auto space-y-10 pt-6">
        {/* {e.images.map ((e)=>( */}
          <Slider />
        {/* ))} */}
        </Col>
      </Row>
      <Row className="py-10 flex justify-center items-center">
        <Col
          md={5}
          sm={8}
          className="p-10 mx-auto flex justify-center items-center border-2 border-Main shadow-xl shadow-black"
        >
          <div className="">
            <span className="text-black text-3xl max-md:text-xl text-start mx-auto font-bold flex-1">
              سعر الخدمة: <span className="text-Main">{dataAll?.price}</span>
            </span>
          </div>
        </Col>
        <Col sm={11} className="mx-auto py-5 ">
          <div className="flex">
            <span className="text-black text-3xl text-start mx-auto font-bold flex-1">
              وصف الخدمة:
            </span>
          </div>
        </Col>
        <Col
          sm={11}
          className="mx-auto py-5 border-Main border-2 shadow-xl shadow-black"
        >
          <Data
            name="وصف الخدمة"
            text={dataAll?.ar_description}
          />
          <div className="border-2 border-Main h-1 w-full my-5"></div>
          <Data 
          dir="ltr"
            name="service Description"
            text={dataAll?.en_description }
          />
        </Col>
      </Row>
      </>
        {/* ))} */}
    </Container>
  );
}

export default ContentService;
