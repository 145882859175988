import { Link } from "react-router-dom";

const Add = ({ link }) => {
  return (
    <>
      <Link to={link || ""}>
        <div
          className={`flex z-10 justify-center items-start fixed bottom-10 left-5 font-bold text-5xl bg-Secondary text-white cursor-pointer w-14 h-14 rounded-full`}
        >
           +
        </div> 
      </Link>
    </>
  );
};

export default Add;
